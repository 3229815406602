import { CleanedRTF } from "@bond-london/graphcms-rich-text";
import classNames from "classnames";
import React, { useState } from "react";
import { RTF } from "../elements";

interface Callout {
  title: string;
  tagline?: string;
  contentRTF?: CleanedRTF;
  link?: string;
}
interface Props {
  title?: string;
  paragraphsRTF?: CleanedRTF;
  heading?: string;
  introduction?: string;
  callouts: Callout[];
}

const translateColsClassName = "lg:-translate-x-desktop-cols-8";

export const Expander: React.FC<Props> = ({
  title,
  paragraphsRTF,
  heading,
  introduction,
  callouts,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>();

  return (
    <section
      data-component="Expander"
      className="relative page-grid max-width grid-flow-row-dense z-10 "
    >
      <div
        className={classNames(
          "py-section",
          "flex bg-medium-blue",
          "row-start-2 col-start-1 col-span-full",
          "lg:col-start-10 lg:col-span-5 lg:row-start-1 lg:row-span-2 lg:transform marginchange",
          activeIndex !== undefined && "lg:-translate-x-desktop-mcols-8"
        )}
      ></div>
      <div
        className={classNames(
          "relative py-section space-y-20px",
          "row-start-1 col-start-2 col-span-12",
          "lg:col-start-2 lg:col-span-6 lg:transform-gpu",
          activeIndex !== undefined && translateColsClassName
        )}
      >
        {title && (
          <h2 className={classNames("h3 text-right-margin")}>{title}</h2>
        )}
        {paragraphsRTF && (
          <RTF
            className={classNames("text-right-margin")}
            content={paragraphsRTF}
          />
        )}
      </div>
      <div
        className={classNames(
          "relative grid grid-gap grid-cols-4 lg:transform-gpu auto-rows-min py-section",
          "row-start-2 col-start-2 col-span-12",
          "lg:col-start-10 lg:col-span-4 lg:row-start-1 lg:row-span-2",
          activeIndex !== undefined && translateColsClassName
        )}
      >
        <button
          className={classNames(
            "bg-left-pattern bg-center bg-no-repeat w-28px h-28px bg-28px",
            "col-start-1 col-span-1 justify-self-center self-center",
            activeIndex === undefined && "invisible"
          )}
          onClick={() => setActiveIndex(undefined)}
        />
        {introduction && (
          <h3
            className={classNames(
              "h5 text-white-2 col-start-2 col-span-3 pb-30px"
            )}
          >
            {introduction}
          </h3>
        )}
        {heading && (
          <h3 className="text-white-2 h4 col-start-2 col-span-3 self-center">
            {heading}
          </h3>
        )}
        {callouts.map((callout, index) => {
          const { title } = callout;
          return (
            <div
              key={index}
              className={classNames(
                "col-start-1 col-span-4 w-full grid grid-gap grid-cols-4 mt-20px",
                activeIndex === undefined ? "mr-0" : "text-right-margin",
                activeIndex === index ? "text-light-blue" : "text-white-2"
              )}
            >
              <button
                className="bg-right-pattern bg-center bg-no-repeat bg-28px h-28px self-center"
                onClick={() => setActiveIndex(index)}
              />
              <p className="p4 col-start-2 col-span-3 text-right-margin">
                {title}
              </p>
            </div>
          );
        })}
      </div>
      <div
        className={classNames(
          "relative pointer-events-none ",
          "row-start-3 col-start-2 col-span-12",
          "lg:row-start-1 lg:col-start-6 lg:col-span-8 lg:py-section"
        )}
      >
        {callouts.map((callout, index) => {
          const { tagline, contentRTF, link } = callout;
          return (
            <div
              key={index}
              className={classNames(
                "grid grid-gap grid-cols-8 overflow-hidden",
                activeIndex === index ? "max-h-massive" : "max-h-0"
              )}
            >
              {tagline && (
                <h4 className="h3 col-start-2 col-span-6 text-right-margin">
                  {tagline}
                </h4>
              )}
              {contentRTF && (
                <RTF
                  content={contentRTF}
                  className="col-start-2 col-span-6 text-right-margin mt-20px space-y-20px"
                />
              )}
              {link && (
                <div className="col-start-5 col-span-3 grid grid-gap grid-cols-3 mt-20px">
                  <a
                    href={link}
                    className="bg-right-pattern bg-right bg-no-repeat bg-28px"
                  >
                    <p className="read-more col-start-2 col-span-2 self-center">
                      Discover more
                    </p>
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};
